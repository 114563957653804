import React from "react"
import { graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layouts/Layout"
import SEO from "../components/SEO"

import Grid from "../components/layouts/Grid"
import Box from "../components/layouts/Box"
import Heading from "../components/atoms/Heading"

const LegalTemplate = ({ data: { mdx: page } }) => {
  return (
    <Layout>
      <SEO title={page.frontmatter.title} />
      <Grid>
        <Box gridColumn={["1 / span 4", "2 / span 6", "3 / span 8"]}>
          <Heading as="h1" fontSize={["h4", "h3", "h1"]}>
            {page.frontmatter.title}
          </Heading>
          <MDXRenderer>{page.body}</MDXRenderer>
        </Box>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
      }
    }
  }
`

export default LegalTemplate
